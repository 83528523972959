import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactHlsPlayer from 'react-hls-player';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const RadyoTrafikIstanbul = 'https://moondigitaledge.radyotvonline.net/radyotrafik/playlist.m3u8';
const RadyoTrafikMarmara = 'https://moondigitaledge.radyotvonline.net/radyotrafikmarmara/playlist.m3u8';
const RadyoTrafikAnkara = 'http://37.247.100.118/stream/462/';
const RadyoTrafikIzmir = 'http://37.247.100.118/stream/179/';

const Radyospor = 'https://moondigitaledge.radyotvonline.net/radyospor/playlist.m3u8';

const Slowtime = 'https://moondigitaledge.radyotvonline.net/slowtime/playlist.m3u8';

const App = () => {
	const playerRef = React.useRef();
	const audioRef = React.useRef();
	const [play, setPlay] = React.useState(false);
	const [playerUri, setPlayerUri] = React.useState('');
	const [current, setCurrent] = React.useState(0);

	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
	};

	const Radios = [
		{
			id: 1,
			name: 'Radyospor',
			image: 'radyospor',
			motto: "Türkiye'nin ilk spor radyosu",
			uri: Radyospor,
			type: 'hls',
		},
		{
			id: 2,
			name: 'Radyo Trafik',
			image: 'radyotrafik',
			motto: "Türkiye'nin ilk ve tek trafik radyosu",
			uri: RadyoTrafikIstanbul,
			type: 'hls',
		},
		{
			id: 3,
			name: 'Radyo Slowtime',
			image: 'radyoslowtime',
			motto: 'Hayatın tadını çıkarmak için şimdi tempoyu düşürme zamanı',
			uri: Slowtime,
			type: 'hls',
		},
		{
			id: 4,
			name: 'Radyo Trafik Marmara',
			image: 'radyotrafik-marmara',
			motto: 'Marmara bölgesi trafik radyosu',
			uri: RadyoTrafikMarmara,
			type: 'hls',
		},
		{
			id: 5,
			name: 'Radyo Trafik Ankara',
			image: 'radyotrafik-ankara',
			motto: 'Ankara trafik radyosu',
			uri: RadyoTrafikAnkara,
			type: 'nonhls',
		},
		{
			id: 6,
			name: 'Radyo Trafik İzmir',
			image: 'radyotrafik-izmir',
			motto: 'İzmir trafik radyosu',
			uri: RadyoTrafikIzmir,
			type: 'nonhls',
		},
	];

	const playRadio = (uri, id, type) => {
		if (uri !== playerUri) {
			setPlay(false);
			setPlayerUri(uri);
			setCurrent(0);

			setTimeout(() => {
				setPlay(true);
				if (type === 'hls') {
					playerRef.current.play();
				} else {
					audioRef.current.audio.current.play();
				}

				setCurrent(id);
			}, 1000);
		} else {
			if (play) {
				setPlay(false);
				if (type === 'hls') {
					playerRef.current.pause();
				} else {
					audioRef.current.audio.current.pause();
				}
				setCurrent(0);
			} else {
				setPlay(true);
				setPlayerUri(uri);
				setCurrent(id);

				setTimeout(() => {
					if (type === 'hls') {
						playerRef.current.play();
					} else {
						audioRef.current.audio.current.play();
					}
				}, 1000);
			}
		}
	};

	return (
		<div className="saran-radios">
			<header>
				<div className="saran-logo">
					<img src="/logo.svg" alt="" />
				</div>
			</header>

			<ReactHlsPlayer playerRef={playerRef} src={playerUri} />
			<AudioPlayer ref={audioRef} src={playerUri} />

			<section>
				<Slider {...settings}>
					{Radios.map((radio, i) => (
						<div className="radio-card" key={i}>
							<div className="radio-logo">
								<img src={`/${radio.image}.svg`} alt={radio.name} />
							</div>

							<div className="radio-motto">{radio.motto}</div>

							<button className={current === radio.id ? 'active' : ''} onClick={() => playRadio(radio.uri, radio.id, radio.type)}>
								Dinle
							</button>
						</div>
					))}
				</Slider>
			</section>
		</div>
	);
};

export default App;
